import ArtCollectible from "../../assets/images/665.png";
import ArtCollectible2 from "../../assets/images/658.png";
import ArtCollectible3 from "../../assets/images/191.png";
import ArtCollectible4 from "../../assets/images/7.png";
import ArtCollectible5 from "../../assets/images/200.png";
import ArtCollectible6 from "../../assets/images/12.png";
import ArtCollectible7 from "../../assets/images/183.png";

const items = [
    {
      id: 1,
      title: 'GAMIFICATION',
      info: [
        'Owning an LLC token will grant you access to play-to-earn gamification on our website.',
        'Owners will have the opportunity to compete in head-to-head competitions with fellow token holders in periodic liquor brewing competitions.',
        'We’ll provide updates on our game development progress throughout the next few months.',
        ]
    },
    {
      id: 2,
      imgSrc:ArtCollectible
    },

    {
      id: 3,
      imgSrc:ArtCollectible3
    },
    {
      id: 4,
      title: 'COMPETITIONS',
      info:[
        'We will be updating the site and adding teaching tools for brewing.',
        'The devs are working on creating a visual explanation and brief description of how our brewing function will work and play out so that users can get a better understanding.',
        'We will be adding a page to the website with information on how the brewing will function, as well as details on how gameplay scoring will work.',
      ]
    },

    {
      id: 5,
      title: 'METAVERSE',
      info:[
        'We will reinvest into the community to bring you guys a party street in our own Metaverse.', 
        'We’ve partnered with some of the best developers to bring an immersive experience where users can interact with the community and bring their tokens to life.',
        'We will be hosting events in the Metaverse with brand sponsors, giveaways, limited merchandise drops, and more!',
      ]
    },
    {
      id: 6,
      imgSrc:ArtCollectible2
    },

    {
      id: 7,
      imgSrc:ArtCollectible4
    },
    {
      id: 8,
      title: 'LIFE EVENTS',
      info:[
        'Happy hours will be held virtually and in real life.',
        'We will also have quarterly events open to the public with the goal of expanding the community.',
        'Token holders attending the event will have VIP access and perks!',
        'For each event, we will be partnering with another NFT community to provide access to non-LLC holders!',
        'We want to welcome other projects into our community and show them what LLC is all about.',
      ]
    },

    {
      id: 9,
      title: 'WEEKLY PODCAST',
      info:[
        'We’ll be holding weekly Twitter spaces once the collection mints out 100%.',
        'On each episode, our moderators and developers will share current and future plans with the community and spice things up with guest speakers – from brewers to glass blowers, to musicians and pioneers in various fields.',
        'We aim to educate the NFT community and shine some light on current issues, news updates, and professional speculations on what’s in store for NFTs in future.'
      ]
    },
    {
      id: 10,
      imgSrc:ArtCollectible5
    },

    {
      id: 11,
      imgSrc:ArtCollectible6
    },
    {
      id: 12,
      title: 'GOVERNANCE',
      info:[
        'Along the line, we want token holders to be able to participate in governance decisions in the LLC.', 
        'They will be able to exercise voting rights on key elements in building the future of our Roadmap.',
        'We\'re brainstorming the most optimal and satisfactory approach for this concept, and you\'ll be hearing more regarding this as soon as possible!'
      ]
    },
    {
      id: 13,
      title: 'COLLABORATIONS',
      info:[
        'Our team of artists and developers will be working in a collaborative effort to merge LLC with leading brands in the brewing industry to create exclusive merchandise for LLC token holders.',
        'Our merchandise store is currently being built, and will host LLC branded merchandise as well as limited collaborations.', 
        'Stay tuned for collaboration announcements on the discord channel.',
      ]
    },
    {
      id: 14,
      imgSrc:ArtCollectible7
    },

  ]
  export default items;