import React from "react";
import "./index.css";
import ArtCollectible from "../../assets/images/1.jpg";
import ArtCollectible2 from "../../assets/images/2.jpg";
import ArtCollectible3 from "../../assets/images/3.jpg";
import ArtCollectible5 from "../../assets/images/9.png";
import ArtCollectible6 from "../../assets/images/5.png";
import ArtCollectible7 from "../../assets/images/8.png";
import ArtCollectible8 from "../../assets/images/201.png";
import ArtCollectible9 from "../../assets/images/660.png";
import ArtCollectible10 from "../../assets/images/654.png";
import ArtCollectible11 from "../../assets/images/191.png";
import ArtCollectible12 from "../../assets/images/200.png";
import ArtCollectible13 from "../../assets/images/663.png";
import ArtCollectible14 from "../../assets/images/184.png";
import ArtCollectible15 from "../../assets/images/188.png";
import Logo from "../../assets/images/logo.png";
import ClubBenefits from "../containerV2/data";

export default function ContainerV2() {
  const renderInfo = (info, index) => {
    return (
      <div className="bllc-render-info">{index + 1}. {info}</div>
    )
  }
  return (

    <main className="container" >
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark bllc-nav-bar">
          <a className="navbar-brand" href="/"><img className="logo" src={Logo} /></a>
          <div className="collapse navbar-collapse bllc-nav-links-container" id="navbarTogglerDemo03">
            <ul className="navbar-nav mt-2 mr-2 mt-lg-0 pt-md-1 bllc-nav-links">
              <li className="nav-item">
                <a className="nav-link text-white" href="#roadMap">ROADMAP</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white" href="#teamDetails">TEAM</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white" href="#faq">FAQ</a>
              </li>
              <li className="nav-item">
                <a className="mdi mdi-instagram nav-item-logo" href="https://instagram.com/LiquorLC"><i className="fab fa-twitter-square"/></a>
              </li>
              <li className="nav-item">
                <a className="mdi mdi-twitter nav-item-logo" href="https://twitter.com/LiquorLC"><i className="fab fa-twitter-square"></i></a>
              </li>
              <li className="nav-item">
                <a className="mdi mdi-discord nav-item-logo" href="https://discord.gg/bWRhYEXEXp"><i className="fab fa-twitter-square"></i></a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <section>
        <div className="bllc-container hero mt-5 d-flex py-5">
          <div className="hero-text text-uppercase text-white sect img-fluid text-center text-md-left">
            <div class="neon-wrapper">
              <div class="neon-text">Welcome to the </div>
              <div class="neon-text-title"><text>Liquor Lovers Club </text></div>
            </div>
            <button className="btn btn-outline-light text-uppercase"><a href="https://discord.gg/bWRhYEXEXp" target="_blank">Join Our Discord</a></button>
          </div>
        </div>
      </section>
      <section className="feature jumbotron" style={{ paddingTop: '4rem' }}>
        <div className="row py-4">
          <div className="col-md-4 pt-md-5 pt-lg-0">
            <div style={{ padding: '0.7rem' }}><img className="img-fluid p-1" src={ArtCollectible} alt="image" /></div>
            <div style={{ padding: '0.7rem' }}><img className="img-fluid p-1" src={ArtCollectible2} alt="image" /></div>
          </div>
          <div className="col-md-8">
            <div >
              <h1 className="bllc-story-heading">11,111 UNIQUE </h1>
              <h2 className="bllc-story-heading-2" style={{ textAlign: 'center' }}>Liquor Lovers</h2>

              <p>Allow us to tell you a "short story" about our protagonist Frank. Who is he, exactly? Frank is a military veteran who fought in the Vietnam War and later developed selective amnesia. He was inching closer to falling in love with the most gorgeous girl he ever met, but the war and his forgetfulness left him drifting.
                Until one fateful day, many years later, when his 'mystery woman' finds him. However, She sends him letters filled with clues to help him find her. She keeps clue-filled lets coming his way. You will be part of a detailed 'quick story' every week that will make these NFTs even more 'market unique'.
                There are plenty of secrets left for Frank to find and solve, one clue and one bar at a time. The Liquor Lovers Club is an NFT collection of 11,111 unique liquor drinking 'Frank' NFTs on the Ethereum Blockchain. Each NFT comprises hundreds of intricate design elements that make it internationally unique. Each NFT comes with a story just as unique as the art itself – a story written with an author's flair.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="collectibles">
        <div className="row py-4">
          <div>
            <h2 className="text bllc-collectibles-title">Club Benefits</h2>

            <div className="accordion bllc-clubbenefits mt-3" id="ClubBenefits">
              {ClubBenefits.map(({ id, title, info, imgSrc }, index) => {
                const evenItem = index % 2 === 0;
                const evenRow = (Math.floor(index / 2)) % 2 === 0;
                return <div className="bllc-clubbenefits__item" data-aos={`fade-${index % 2 == 0 ? 'right' : 'left'}`} data-aos-delay={(index + 1) + `25`} key={id}>
                  {evenRow ?
                    <> {
                      evenItem ?
                        <>
                          <div class="club-benefits-text-title" style={{ marginTop: '1rem' }}>{title} </div>
                          <div>
                            {info.map(renderInfo)}
                          </div>                        </> :
                        <img class="bllc-club-benefits-images" src={imgSrc} width="100%" height="100%" />
                    }
                    </> :
                    <> {
                      evenItem ?
                        <img class="bllc-club-benefits-images-2" src={imgSrc} width="100%" height="100%" />
                        :
                        <>
                          <div class="club-benefits-text-title-2">
                            {title}
                          </div>
                          <div>
                            {info.map(renderInfo)}
                          </div>
                        </>
                    }
                    </>}
                </div>
              })}
            </div>
          </div>
        </div>

      </section>

      <section className="roadmap jumbotron" id="roadMap">
        <h2 className="text bllc-collectibles-title">Road Map</h2>

        <section id="timeline">
          <div className="tl-item">
            <div className="tl-bg" style={{ backgroundImage: `url(${ArtCollectible14})` }}></div>

            <div className="tl-year">
              <p className="f2 heading--sanSerif">0%</p>
            </div>

            <div className="tl-content" >
              <h1 className="tl-title">The Bar is Open</h1>
              <ul className="roadmap-content" >
                <li> Welcome to the private party of Liquor Lovers.</li>
                <li> Private discord channel will be made available for our liquor lover NFT holders.</li>
              </ul>
            </div>
          </div>
          <div className="tl-item">
            <div className="tl-bg" style={{ backgroundImage: `url(${ArtCollectible15})` }}></div>
            <div className="tl-year">
              <p className="f2 heading--sanSerif">20%</p>
            </div>
            <div className="tl-content">
              <h1 className="f3 text--accent ttu" className="tl-title">Open your tab</h1>
              <ul className="roadmap-content">
                <li> Community wallet will be opened and active liquor lovers in discord will be rewarded handsomely to ensure project's longevity.</li>
              </ul>
            </div>

          </div>

          <div className="tl-item">
            <div className="tl-bg" style={{ backgroundImage: `url(${ArtCollectible3})` }}></div>
            <div className="tl-year">
              <p className="f2 heading--sanSerif">40%</p>
            </div>
            <div className="tl-content">
              <h1 className="f3 text--accent ttu" className="tl-title">The Quest </h1>
              <ul className="roadmap-content">
                <li>Active token holders will be ranked and a boost will be given to their NFT token to help their 'Frank' reunite with his mystery lover.</li>
              </ul>
            </div>
          </div>

          <div className="tl-item">
            <div className="tl-bg" style={{ backgroundImage: `url(${ArtCollectible7})` }}></div>
            <div className="tl-year">
              <p className="f2 heading--sanSerif">60%</p>
            </div>

            <div className="tl-content">
              <h1 className="f3 text--accent ttu" className="tl-title">The Reunion</h1>
              <ul className="roadmap-content">
                <li>LLC token holders will see their 'Franks' reuniting with his mystery woman via a new NFT free of cost.</li>
                </ul>
            </div>
          </div>
          <div className="tl-item">

            <div className="tl-bg" style={{ backgroundImage: `url(${ArtCollectible5})` }}></div>

            <div className="tl-year">
              <p className="f2 heading--sanSerif">80%</p>
            </div>


            <div className="tl-content">
              <h1 className="f3 text--accent ttu" className="tl-title">Merch drop</h1>
              <ul className="roadmap-content">
                <li>LLC token holders get cool merchandise at discounted prices.</li>
                <li> A meet-up will be organized for Liquor Lover token holders across the world. At this event, your first party drinks will be on us.</li>
              </ul>
            </div>
          </div>
          <div className="tl-item">

            <div className="tl-bg" style={{ backgroundImage: `url(${ArtCollectible6})` }}></div>

            <div className="tl-year">
              <p className="f2 heading--sanSerif">100%</p>
            </div>

            <div className="tl-content">
              <h1 className="f3 text--accent ttu" className="tl-title">Launch P2E Party Street</h1>
              <ul className="roadmap-content">
                <li>An exclusive liquor lovers party street Metaverse will be launched.</li>
                <li>Liquor lovers can either explore cheap college bars for those who fancy a beer pong title or high end resto bars for token holders who fancy a virtual date.</li>
              </ul>
            </div>
          </div>
        </section>
      </section>

      <section className="team text-center py-5 my-4" id="teamDetails">
        <h3 className="text-uppercase">Meet the Team</h3>
        <h2 style={{ padding: "2rem", justifyContent: "center" }}>Here is the team organizing our 'drinks party'. We also have fifteen other experts handling our socials, discord, marketing campaigns, and more</h2>
        <div className="row">
          <div className="col-md-4 pt-3">
            <img className="img-fluid" src={ArtCollectible9} style={{ width: "280px", height: "280px" }} alt="image" />
            <p className="pt-3" style={{textAlign : "center"}} id="teamname">AARVI</p>
            <span>Founder, Bloackchain Developer & Owner</span>
          </div>
          <div className="col-md-4 pt-3">
            <img className="img-fluid" src={ArtCollectible8} style={{ width: "280px", height: "280px" }} alt="image" />
            <p className="pt-3" style={{textAlign : "center"}}  id="teamname">SEAN</p>
            <span>Development</span>
          </div>
          <div className="col-md-4 pt-3">
            <img className="img-fluid" src={ArtCollectible10} style={{ width: "280px", height: "280px" }} alt="image" />
            <p className="pt-3" style={{textAlign : "center"}}  id="teamname">CR7</p>
            <span>Development</span>
          </div>
          <div className="col-md-4 pt-3">
            <img className="img-fluid" src={ArtCollectible11} style={{ width: "280px", height: "280px" }} alt="image" />
            <p className="pt-3"style={{textAlign : "center"}}  id="teamname">PEER</p>
            <span>Artist</span>
          </div>
          <div className="col-md-4 pt-3">
            <img className="img-fluid" src={ArtCollectible13} style={{ width: "280px", height: "280px" }} alt="image" />
            <p className="pt-3" style={{textAlign : "center"}}  id="teamname">CS GO</p>
            <span>Branding</span>
          </div>
          <div className="col-md-4 pt-3">
            <img className="img-fluid" src={ArtCollectible12} style={{ width: "280px", height: "280px" }} alt="image" />
            <p className="pt-3" style={{textAlign : "center"}} id="teamname">PENMAN</p>
            <span>Content Creator</span>
          </div>
          <div className="col-md-12 pt-3">
            <img className="img-fluid" src={ArtCollectible} style={{ width: "280px", height: "280px" }} alt="image" />
            <p className="pt-3" style={{textAlign : "center"}} id="teamname">FREND</p>
            <span>Marketing</span>
          </div>
        </div>
      </section>

      <section className="faq py-5 my-5 jumbotron" id="faq">
        <h3 className="text-uppercase" style={{marginLeft : "1.5rem"}}>frequently asked questions</h3>
        <div className="accordion pt-4" id="accordionExample" style={{ padding: "2rem" }}>
          <hr />
          <div className="card">
            <div className="card-header" id="headingOne">
              <h2 className="mb-0">
                <button className="btn btn-link btn-block text-left question collapsed" type="button" data-toggle="collapse"
                  data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  Q1. When is the official launch?
                </button>
              </h2>
            </div>
            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div className="card-body">
                TBD
              </div>
            </div>
          </div>
          <hr />
          <div className="card">
            <div className="card-header" id="headingTwo">
              <h2 className="mb-0">
                <button className="btn btn-link btn-block text-left question collapsed" type="button" data-toggle="collapse"
                  data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Q2. What will the mint price be?
                </button>
              </h2>
            </div>
            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div className="card-body">
                TBD
              </div>
            </div>
          </div>
          <hr />
          <div className="card">
            <div className="card-header" id="headingThree">
              <h2 className="mb-0">
                <button className="btn btn-link btn-block text-left question collapsed" type="button" data-toggle="collapse"
                  data-target="#collapseThree" aria-expanded="false" aria-controls="collapsethree">
                  Q3. What is the supply for this collection?
                </button>
              </h2>
            </div>
            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
              <div className="card-body">
                A total of 11,111 will be available on mint day.
              </div>
            </div>
          </div>
          <hr />
          <div className="card">
            <div className="card-header" id="headingFour">
              <h2 className="mb-0">
                <button className="btn btn-link btn-block text-left question collapsed" type="button" data-toggle="collapse"
                  data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  Q4. On which network is Liquour Lovers Club?
                </button>
              </h2>
            </div>

            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
              <div className="card-body">
                Liquour Lovers Club will use the Ethereum network, and the collection will be hosted on Opensea.
              </div>
            </div>
          </div>
          <hr />
          <div className="card">
            <div className="card-header" id="headingFive">
              <h2 className="mb-0">
                <button className="btn btn-link btn-block text-left question collapsed" type="button" data-toggle="collapse"
                  data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  Q5. How do I get whitelisted
                </button>
              </h2>
            </div>
            <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
              <div className="card-body">
                There are many ways to get whitelisted, however there is a limited number of tickets! Please join our Discord to get more information.
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>

      <section className="text-center cta py-5">
        <h3 className="text-uppercase text-center">Join the liquor lovers club</h3>
        <p style={{ textAlign: 'center' }}> Join a genuine community of Drunkards</p>
        <button className="btn btn-outline-light text-uppercase"><a href="https://discord.gg/BRT35dtz" target="_blank">Join Our Discord</a>
        </button>
      </section>

      <footer className="text-center py-5">
        <div className="d-flex flex-row justify-content-center">
          <div className="p-0"><img src={Logo} alt="logo" style={{ height: "80px", width: "80px" }} /></div>
          <div className="p-2">
            <h1 className="text-uppercase pl-2 pt-md-2">Liquor Lovers Club</h1>
          </div>
        </div>
        <hr className="mx-5" />
        <p>&copy; 2021 Liquor Club | All Rights Reserved</p>
        <p>Disclaimer: NFTs have high investment risks. Please do your own research and invest responsibly.</p>
      </footer>
    </main>
  )
}
